<template>
  <div class="action-block">
    <div class="common-action dark-gray" @click="showModal">
      <i :class="block.icon"></i> {{ block.name }}
    </div>

    <b-modal id="redemption-modal" v-model="isShowModal" title="Redemption" size="xl">
      <TextInput v-model="block.name" label="Label" />

      <div class="row">
        <div class="col-md-6">
          <SelectInput v-model="block.options.day_to_expire" :options="timeLimitExpireOptions" name="day_to_expire"
            label="Day to Expire" />
        </div>
        <div class="col-md-6">
          <div class="mt-4 pt-3">
            <span class="pr-2" style="font-weight:600;">OFF</span>
            <b-form-checkbox v-model="block.options.active" name="check-button" switch inline>
              ON
            </b-form-checkbox>
          </div>
          <div class="help-block mt-3">
            Turn on to wait for it to be redeemed or expired to move forward.
          </div>
        </div>
      </div>
      <div class="form-group">
        <MergeFieldsGroup :show-redemption="true" :show-keyword="true" :show-contact-link="true" @selected="appendField" />
      </div>

      <div class="row mt-5">
        <div class="col-md-12">
          <div class="d-flex mb-3">
            <label class="mr-2">via SMS</label>
            <b-form-checkbox v-model="block.options.send_sms" switch></b-form-checkbox>
          </div>
        </div>
        <div v-if="block.options.send_sms" class="col-md-12">
          <div class="form-group">
            <label>Content</label>
            <div class="d-block w-100 textarea-wrapper">
              <textarea v-model="block.options.content" class="form-control" @focus="selectEditor"></textarea>
              <span class="d-block opt-out"><small><i>Reply STOP to opt out</i> (this is appended to this
                  message)</small></span>
            </div>
            <SMSLength :text="block.options.content" :has-optout="true" />
            <div v-if="!isContentValid" class="text-danger mt-1">
              Code merge field is requried
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex mb-3">
            <label class="mr-2">via Email</label>
            <b-form-checkbox v-model="block.options.send_email" switch></b-form-checkbox>
          </div>
        </div>
        <div v-if="block.options.send_email" class="col-md-12">
          <div v-if="showEmailAccounts" class="form-group">
            <label>Email account <span v-b-tooltip.hover class="cursor-pointer"
                title="You can leave this blank to use system default email client."><i
                  class="uil uil-question-circle"></i></span></label>
            <multiselect v-model="block.options.integration_user" :options="emailIntegrations" :multiple="false"
              :allow-empty="false" track-by="id" placeholder="Select Account" label="name"></multiselect>
          </div>
          <div class="row">
            <div class="col-md-6">
              <TextInput v-model="block.options.from_name" label="From name" name="name" rules="required"></TextInput>
            </div>
            <div class="col-md-6">
              <TextInput v-model="block.options.from_email" type="email" label="From email" rules="required" name="email">
              </TextInput>
            </div>
            <div class="col-md-12">
              <TextInput v-model="block.options.subject" label="Subject" name="subject" rules="required"
                @focus="selectEditor"></TextInput>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Content</label>
                <VueEditor v-model="block.options.content_email" @focus="selectVueEditor" />
                <div v-if="!isEmailContentValid" class="text-danger mt-1">
                  Code merge field is requried
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <p>
        These messages will go out the number of days prior to the offer
        expiring, this creates urgency and increases conversion rates.
      </p>

      <table class="table table-bordered mt-4 mb-4">
        <thead>
          <tr>
            <th style="width:40%">Time</th>
            <th style="">Message</th>
            <th style="width:100px;"> </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(reminder, index) in block.options.reminders" :key="index">
            <td>
              <div class="row">
                <div class="col-md-6">
                  <SelectInput v-model="reminder.days" :options="timeLimitOptions" label="Days" name="Days" />
                </div>
                <div class="col-md-6">
                  <label>Time</label>
                  <TimePicker v-model="reminder.time" />
                </div>
              </div>
            </td>
            <td>
              <ValidationProvider v-slot="{ failed, errors }" rules="required" name="message">
                <textarea v-model="reminder.message" name="message" class="form-control mt-3" rows="2"
                  :class="{ 'is-invalid': failed }" @focus="selectEditor"></textarea>
                <b-form-invalid-feedback v-if="errors.length">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                <SMSLength :text="reminder.message" />
              </ValidationProvider>
            </td>
            <td class="text-center align-middle">
              <button class="btn btn-outline-danger btn-sm" type="button" @click="removeRemimder(index)">
                <i class="uil uil-trash-alt"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td class="text-center">
              <button class="btn btn-outline-success btn-sm" type="button" @click="addReminder">
                <i class="uil uil-plus"></i> Add
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="mt-2">
        <strong><span v-b-tooltip.hover title="You can edit this timezone in settings page.">Timezone: {{ settingTimezone
        }}</span></strong>
        <div>Current Time: {{ serverTime }}</div>
      </div>

      <template v-slot:modal-footer>
        <b-button variant="primary" :disabled="buttonDisbaled" @click="save">Save</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import { VueEditor } from "vue2-editor";
import SMSLength from '../../../Common/SMSLength'
import moment from 'moment-timezone'

export default {
  components: { SMSLength, VueEditor, Multiselect },

  props: {
    block: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      isShowModal: false,
      timePicker: {
        wrap: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: 'G:i K',
      },
      selectedEditor: null,
      emailIntegrations: [],
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    showEmailAccounts() {
      return this.emailIntegrations && this.emailIntegrations.length > 1
    },

    timeLimitExpireOptions() {
      return Array(31)
        .fill()
        .map((_, i) => {
          return { value: i + 1, label: i + 1 }
        })
    },
    timeLimitOptions() {
      return Array(32)
        .fill()
        .map((_, i) => {
          return { value: i, label: i }
        })
    },

    serverTime() {
      let timezone = this.user && this.user.business ? this.user.business.timezone : moment.tz.guess()

      return moment()
        .tz(timezone)
        .format('MMM D, YYYY hh:mm A')
    },
    settingTimezone() {
      let timezone = this.user && this.user.business ? this.user.business.timezone : moment.tz.guess()

      return `(UTC${moment()
        .tz(timezone)
        .format('Z')}) ${timezone}`
    },

    isContentValid() {
      return this.block.options.content &&
        (this.block.options.content.match(/\[redemption_code\]/) || this.block.options.content.match(/\[coupon_code\]/)
          || this.block.options.content.match(/\[static_code\]/) || this.block.options.content.match(/\[dynamic_code\]/))
    },

    isEmailContentValid() {
      return this.block.options.content_email &&
        (this.block.options.content_email.match(/\[redemption_code\]/) || this.block.options.content_email.match(/\[coupon_code\]/)
          || this.block.options.content_email.match(/\[static_code\]/) || this.block.options.content_email.match(/\[dynamic_code\]/))
    },

    buttonDisbaled() {
      return this.block.options.send_sms && !this.isContentValid || this.block.options.send_email && !this.isEmailContentValid
    }
  },

  mounted() {
    if (this.block.isNewBlock) {
      this.block.isNewBlock = false
      this.showModal()
    }
  },

  methods: {
    getEmailIntegrations() {
      this.$store.dispatch('integration/selectOptions')
        .then(res => {
          this.emailIntegrations = res
          if (this.emailIntegrations && this.emailIntegrations.length === 1) {
            this.block.options.integration_user = this.emailIntegrations[0]
          }
        })
        .catch(() => { })
    },

    removeRemimder(index) {
      this.block.options.reminders.splice(index, 1)
    },

    save() {
      let success = true
      this.block.options.reminders.forEach((item) => {
        success = success && !!item.message
      });

      if (!success) {
        Vue.$toast.open({
          message: 'Reminder Message can\'t be empty',
          type: 'default',
          duration: 3000,
        })
      } else {
        this.isShowModal = false
      }
    },

    showModal() {
      this.isShowModal = true
      this.getEmailIntegrations()
    },

    addReminder() {
      this.block.options.reminders.push({ message: '', time: '08:00', days: 1 })
    },

    appendField(field) {
      if (!this.selectedEditor) return

      if (this.selectedEditor !== 'vueEditor') {
        this.selectedEditor.focus()
      }
      document.execCommand('insertText', false, field)
    },

    selectEditor(event) {
      this.selectedEditor = event.target
    },

    selectVueEditor(event) {
      this.selectedEditor = 'vueEditor'
    },
  }
}
</script>